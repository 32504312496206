@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply w-full max-w-none px-[130px];
  }
  .container-lg {
    @apply w-full px-[32px] md:w-[94%] lg:w-[86.3%] xl:w-[86.3%] mx-auto md:px-0;
  }
  .container-xl {
    @apply w-full px-[32px] md:w-[94%] lg:w-[90%] xl:w-[90%] mx-auto md:px-0;
  }
}
@layer base {
  @font-face {
    font-family: "Ultine-ConBoo";
    src: url(./assets/fonts/ultine-cond-regular.ttf);
  }
  @font-face {
    font-family: "Ultine-ConBooBold";
    src: url(./assets/fonts/ultine-cond-bold.ttf);
  }
  @font-face {
    font-family: "Ultine-ConBooLight";
    src: url(./assets/fonts/ultine-cond-light.ttf);
  }
  @font-face {
    font-family: "Ultine-ConBooMedium";
    src: url(./assets/fonts/ultine-cond-medium.ttf);
  }
  @font-face {
    font-family: "Ultine-NorDemRegular";
    src: url(./assets/fonts/ultine-norm-book.ttf);
  }
  @font-face {
    font-family: "Ultine-NorDemBold";
    src: url(./assets/fonts/ultine-norm-bold.ttf);
  }
  @font-face {
    font-family: "Ultine-NorDemLight";
    src: url(./assets/fonts/ultine-norm-light.ttf);
  }
  @font-face {
    font-family: "Ultine-NorDemMedium";
    src: url(./assets/fonts/ultine-norm-medium.ttf);
  }
  @font-face {
    font-family: "Din-NextLight";
    src: url(./assets/fonts/DINNextW1G-Light.otf);
  }
}
/* body {
  font-family: "Ultine-Regular", "sans-serif";
} */
.bannerBackground {
  background-image: url("./assets/icons/banner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  /* pointer-events: none; */
}
.productMain {
  background-image: url("./assets/icons/product1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
  object-fit: cover;
}
.fotterBackground {
  background-image: url("./assets/icons/fotterBg.png");
  object-fit: cover;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* pointer-events: none; */
}

.content {
  position: relative;
  z-index: 2;
  padding: 20px;
  color: white;
}

.page-transition {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.page-entering {
  opacity: 0;
}

.page-entered {
  opacity: 1;
}

/* NumberIncrement.css */
.number-increment {
  font-size: 2em;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
